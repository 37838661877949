import repeat from './repeat'

export default function typedKeys<T>(o: T): (keyof T & (string | number))[] {
  if (o == null || typeof o !== 'object') {
    return []
  }
  if (Array.isArray(o)) {
    return repeat(o.length, n => n as keyof T & number)
  }
  return Object.keys(o) as (keyof T & string)[]
}
